define("discourse/plugins/discourse-events/discourse/controllers/admin-events-event", ["exports", "@ember/array", "@ember/controller", "@ember/object/computed", "discourse/lib/show-modal", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/mixins/message"], function (_exports, _array, _controller, _computed, _showModal, _decorators, _message) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _controller.default.extend(_message.default, (_dec = (0, _decorators.default)("selectedEvents.[]", "hasEvents"), _dec2 = (0, _decorators.default)("hasEvents"), (_obj = {
    hasEvents: (0, _computed.notEmpty)("events"),
    selectedEvents: (0, _array.A)(),
    selectAll: false,
    order: null,
    asc: null,
    viewName: "event",
    deleteDisabled(selectedEvents, hasEvents) {
      return !hasEvents || !selectedEvents.length;
    },
    selectDisabled(hasEvents) {
      return !hasEvents;
    },
    actions: {
      showSelect() {
        this.toggleProperty("showSelect");
        if (!this.showSelect) {
          this.setProperties({
            selectedEvents: (0, _array.A)(),
            selectAll: false
          });
        }
      },
      modifySelection(events, checked) {
        if (checked) {
          this.get("selectedEvents").pushObjects(events);
        } else {
          this.get("selectedEvents").removeObjects(events);
        }
      },
      openDelete() {
        var _this = this;
        const modal = (0, _showModal.default)("events-confirm-event-deletion", {
          model: {
            events: this.selectedEvents
          }
        });
        modal.setProperties({
          onDestroyEvents: function () {
            let destroyedEvents = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : null;
            let destroyedTopicsEvents = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : null;
            if (destroyedEvents) {
              _this.get("events").removeObjects(destroyedEvents);
            }
            if (destroyedTopicsEvents) {
              const destroyedTopicsEventIds = destroyedTopicsEvents.map(e => e.id);
              _this.get("events").forEach(event => {
                if (destroyedTopicsEventIds.includes(event.id)) {
                  event.set("topics", null);
                }
              });
            }
          },
          onCloseModal: () => {
            this.send("showSelect");
          }
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "deleteDisabled", [_dec], Object.getOwnPropertyDescriptor(_obj, "deleteDisabled"), _obj), _applyDecoratedDescriptor(_obj, "selectDisabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "selectDisabled"), _obj)), _obj)));
});