define("discourse/plugins/discourse-events/discourse/components/events-connection-row", ["exports", "@ember/component", "@ember/object/computed", "@ember/service", "discourse-common/utils/decorators", "discourse/plugins/discourse-events/discourse/models/connection", "discourse/plugins/discourse-events/discourse/components/modal/events-connection-filters"], function (_exports, _component, _computed, _service, _decorators, _connection, _eventsConnectionFilters) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function filtersMatch(filters1, filters2) {
    if (filters1 && !filters2 || !filters1 && filters2) {
      return false;
    }
    if (!filters1 && !filters2) {
      return true;
    }
    if (filters1.length !== filters2.length) {
      return false;
    }
    return filters1.every(f1 => filters2.some(f2 => {
      return f2.query_column === f1.query_column && f2.query_value === f1.query_value;
    }));
  }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("connection.user.username", "connection.category_id", "connection.source_id", "connection.client", "connection.from_time", "connection.to_time", "connection.filters.[]", "connection.filters.@each.query_column", "connection.filters.@each.query_value"), _dec2 = (0, _decorators.default)("connectionChanged", "connection.user.username", "connection.category_id", "connection.source_id", "connection.client"), _dec3 = (0, _decorators.default)("connectionChanged"), _dec4 = (0, _decorators.default)("syncDisabled"), _dec5 = (0, _decorators.default)("connectionChanged", "loading"), _dec6 = (0, _decorators.default)("hasFilters"), (_obj = {
    tagName: "tr",
    attributeBindings: ["connection.id:data-connection-id"],
    classNameBindings: [":events-connection-row", "hasChildCategory:has-child-category"],
    hasFilters: (0, _computed.notEmpty)("connection.filters"),
    hasChildCategory: (0, _computed.readOnly)("connection.category.parent_category_id"),
    modal: (0, _service.service)(),
    didReceiveAttrs() {
      this._super();
      this.set("currentConnection", JSON.parse(JSON.stringify(this.connection)));
    },
    willDestroyElement() {
      this._super(...arguments);
      this.setMessage("info", "info");
    },
    connectionChanged(username, categoryId, sourceId, client, fromTime, toTime, filters) {
      const cc = this.currentConnection;
      return !cc.user && username || cc.user && cc.user.username !== username || cc.category_id !== categoryId || cc.source_id !== sourceId || cc.client !== client || cc.from_time !== fromTime || cc.to_time !== toTime || !filtersMatch(filters, cc.filters);
    },
    saveDisabled(connectionChanged, username, categoryId, sourceId, client) {
      return !connectionChanged || !username || !categoryId || !sourceId || !client;
    },
    saveClass(connectionChanged) {
      return connectionChanged ? "btn-primary save-connection" : "save-connection";
    },
    syncClass(syncDisabled) {
      return syncDisabled ? "sync-connection" : "btn-primary sync-connection";
    },
    syncDisabled(connectionChanged, loading) {
      return connectionChanged || loading;
    },
    filterClass(hasFilters) {
      let classes = "show-filters";
      if (hasFilters) {
        classes += " btn-primary";
      }
      return classes;
    },
    actions: {
      updateUser(usernames) {
        const connection = this.connection;
        if (!connection.user) {
          connection.set("user", {});
        }
        connection.set("user.username", usernames[0]);
      },
      openFilters() {
        this.modal.show(_eventsConnectionFilters.default, {
          model: {
            connection: this.get("connection")
          }
        });
      },
      saveConnection() {
        const connection = this.connection;
        if (!connection.source_id) {
          return;
        }
        const data = {
          id: connection.id,
          category_id: connection.category_id,
          client: connection.client,
          source_id: connection.source_id,
          user: connection.user
        };
        if (connection.filters) {
          data.filters = JSON.parse(JSON.stringify(connection.filters));
        }
        this.set("loading", true);
        _connection.default.update(data).then(result => {
          if (result) {
            this.setProperties({
              currentConnection: result.connection,
              connection: _connection.default.create(result.connection)
            });
          } else if (this.currentSource.id !== "new") {
            this.set("connection", JSON.parse(JSON.stringify(this.currentConnection)));
          }
        }).finally(() => {
          this.set("loading", false);
        });
      },
      syncConnection() {
        const connection = this.connection;
        this.set("loading", true);
        _connection.default.sync(connection).then(result => {
          if (result.success) {
            this.setMessage("sync_started", "success");
          } else {
            this.setMessage("sync_failed_to_start", "error");
          }
        }).finally(() => {
          this.set("loading", false);
          setTimeout(() => {
            if (!this.isDestroying && !this.isDestroyed) {
              this.setMessage("info", "info");
            }
          }, 5000);
        });
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "connectionChanged", [_dec], Object.getOwnPropertyDescriptor(_obj, "connectionChanged"), _obj), _applyDecoratedDescriptor(_obj, "saveDisabled", [_dec2], Object.getOwnPropertyDescriptor(_obj, "saveDisabled"), _obj), _applyDecoratedDescriptor(_obj, "saveClass", [_dec3], Object.getOwnPropertyDescriptor(_obj, "saveClass"), _obj), _applyDecoratedDescriptor(_obj, "syncClass", [_dec4], Object.getOwnPropertyDescriptor(_obj, "syncClass"), _obj), _applyDecoratedDescriptor(_obj, "syncDisabled", [_dec5], Object.getOwnPropertyDescriptor(_obj, "syncDisabled"), _obj), _applyDecoratedDescriptor(_obj, "filterClass", [_dec6], Object.getOwnPropertyDescriptor(_obj, "filterClass"), _obj)), _obj)));
});