define("discourse/plugins/discourse-events/discourse/controllers/events-confirm-event-deletion", ["exports", "@ember/controller", "discourse/mixins/modal-functionality", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-events/discourse/models/event"], function (_exports, _controller, _modalFunctionality, _decorators, _I18n, _event) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const DELETE_TARGETS = ["events_only", "events_and_topics", "topics_only"];
  var _default = _exports.default = _controller.default.extend(_modalFunctionality.default, (_dec = (0, _decorators.default)("model.events"), _dec2 = (0, _decorators.default)("deleteTarget"), (_obj = {
    deleteTargets: DELETE_TARGETS.map(t => ({
      id: t,
      name: _I18n.default.t(`admin.events.event.delete.${t}`)
    })),
    deleteTarget: "events_only",
    eventCount(events) {
      return events.length;
    },
    btnLabel(deleteTarget) {
      return `admin.events.event.delete.${deleteTarget}_btn`;
    },
    actions: {
      delete() {
        const events = this.model.events;
        const eventIds = events.map(e => e.id);
        const target = this.deleteTarget;
        const opts = {
          event_ids: eventIds,
          target
        };
        this.set("destroying", true);
        _event.default.destroy(opts).then(result => {
          if (result.success) {
            this.onDestroyEvents(events.filter(e => result.destroyed_event_ids.includes(e.id)), events.filter(e => result.destroyed_topics_event_ids.includes(e.id)));
            this.onCloseModal();
            this.send("closeModal");
          } else {
            this.set("model.error", result.error);
          }
        }).finally(() => this.set("destroying", false));
      },
      cancel() {
        this.onCloseModal();
        this.send("closeModal");
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "eventCount", [_dec], Object.getOwnPropertyDescriptor(_obj, "eventCount"), _obj), _applyDecoratedDescriptor(_obj, "btnLabel", [_dec2], Object.getOwnPropertyDescriptor(_obj, "btnLabel"), _obj)), _obj)));
});