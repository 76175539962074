define("discourse/plugins/discourse-events/discourse/components/modal/event-rsvp", ["exports", "@ember/component", "@ember/application", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/user", "discourse-common/utils/decorators", "I18n", "@ember/template-factory"], function (_exports, _component, _application, _object, _ajax, _ajaxError, _user, _decorators, _I18n, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    class="event-rsvp-modal"
    @title={{this.title}}
    @closeModal={{@closeModal}}
    @flash={{this.flash}}
  >
    <:body>
      <div class="header">
        <ul class="types nav nav-pills">
          <li>
            <a
              href
              {{on "click" (fn this.setType "going")}}
              class={{this.goingNavClass}}
            >
              {{i18n "event_rsvp.going.label"}}
            </a>
          </li>
        </ul>
      </div>
      <div class="list">
        {{#if this.loadingList}}
          {{loading-spinner size="small"}}
        {{else}}
          <ul>
            {{#each this.filteredList as |user|}}
              <li>
                <UserInfo @user={{user}}>
                  {{#if this.currentUser.staff}}
                    <DButton
                      class="btn compose-pm"
                      @action={{fn this.composePrivateMessage user}}
                      @icon="envelope"
                      @label="user.private_message"
                    />
                  {{/if}}
                </UserInfo>
              </li>
            {{/each}}
          </ul>
        {{/if}}
      </div>
    </:body>
  </DModal>
  */
  {
    "id": "0ygQbMFg",
    "block": "[[[8,[39,0],[[24,0,\"event-rsvp-modal\"]],[[\"@title\",\"@closeModal\",\"@flash\"],[[30,0,[\"title\"]],[30,1],[30,0,[\"flash\"]]]],[[\"body\"],[[[[1,\"\\n    \"],[10,0],[14,0,\"header\"],[12],[1,\"\\n      \"],[10,\"ul\"],[14,0,\"types nav nav-pills\"],[12],[1,\"\\n        \"],[10,\"li\"],[12],[1,\"\\n          \"],[11,3],[24,6,\"\"],[16,0,[30,0,[\"goingNavClass\"]]],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"setType\"]],\"going\"],null]],null],[12],[1,\"\\n            \"],[1,[28,[35,3],[\"event_rsvp.going.label\"],null]],[1,\"\\n          \"],[13],[1,\"\\n        \"],[13],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n    \"],[10,0],[14,0,\"list\"],[12],[1,\"\\n\"],[41,[30,0,[\"loadingList\"]],[[[1,\"        \"],[1,[28,[35,5],null,[[\"size\"],[\"small\"]]]],[1,\"\\n\"]],[]],[[[1,\"        \"],[10,\"ul\"],[12],[1,\"\\n\"],[42,[28,[37,7],[[28,[37,7],[[30,0,[\"filteredList\"]]],null]],null],null,[[[1,\"            \"],[10,\"li\"],[12],[1,\"\\n              \"],[8,[39,8],null,[[\"@user\"],[[30,2]]],[[\"default\"],[[[[1,\"\\n\"],[41,[30,0,[\"currentUser\",\"staff\"]],[[[1,\"                  \"],[8,[39,9],[[24,0,\"btn compose-pm\"]],[[\"@action\",\"@icon\",\"@label\"],[[28,[37,2],[[30,0,[\"composePrivateMessage\"]],[30,2]],null],\"envelope\",\"user.private_message\"]],null],[1,\"\\n\"]],[]],null],[1,\"              \"]],[]]]]],[1,\"\\n            \"],[13],[1,\"\\n\"]],[2]],null],[1,\"        \"],[13],[1,\"\\n\"]],[]]],[1,\"    \"],[13],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"user\"],false,[\"d-modal\",\"on\",\"fn\",\"i18n\",\"if\",\"loading-spinner\",\"each\",\"-track-array\",\"user-info\",\"d-button\"]]",
    "moduleName": "discourse/plugins/discourse-events/discourse/components/modal/event-rsvp.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend((_dec = (0, _decorators.observes)("type", "model.topic"), _dec2 = (0, _decorators.default)("type"), _dec3 = (0, _decorators.default)("userList"), (_obj = {
    userList: [],
    type: "going",
    title: _I18n.default.t("event_rsvp.modal.title"),
    didReceiveAttrs() {
      this._super();
      this.setUserList();
    },
    setUserList() {
      this.set("loadingList", true);
      const type = this.get("type");
      const topic = this.get("model.topic");
      let usernames = topic.get(`event.${type}`);
      if (!usernames || !usernames.length) {
        return;
      }
      (0, _ajax.ajax)("/discourse-events/rsvp/users", {
        data: {
          usernames
        }
      }).then(response => {
        let userList = response.users || [];
        this.setProperties({
          userList,
          loadingList: false
        });
      }).catch(e => {
        this.set("flash", (0, _ajaxError.extractError)(e));
      }).finally(() => {
        this.setProperties({
          loadingList: false
        });
      });
    },
    goingNavClass(type) {
      return type === "going" ? "active" : "";
    },
    filteredList(userList) {
      const currentUser = this.get("currentUser");
      if (currentUser) {
        userList.sort(a => {
          if (a.username === currentUser.username) {
            return -1;
          } else {
            return 1;
          }
        });
      }
      return userList;
    },
    setType(type) {
      event?.preventDefault();
      this.set("type", type);
    },
    composePrivateMessage(user) {
      const controller = (0, _application.getOwner)(this).lookup("controller:application");
      this.closeModal();
      controller.send("composePrivateMessage", _user.default.create(user));
    }
  }, (_applyDecoratedDescriptor(_obj, "setUserList", [_dec], Object.getOwnPropertyDescriptor(_obj, "setUserList"), _obj), _applyDecoratedDescriptor(_obj, "goingNavClass", [_dec2], Object.getOwnPropertyDescriptor(_obj, "goingNavClass"), _obj), _applyDecoratedDescriptor(_obj, "filteredList", [_dec3], Object.getOwnPropertyDescriptor(_obj, "filteredList"), _obj), _applyDecoratedDescriptor(_obj, "setType", [_object.action], Object.getOwnPropertyDescriptor(_obj, "setType"), _obj), _applyDecoratedDescriptor(_obj, "composePrivateMessage", [_object.action], Object.getOwnPropertyDescriptor(_obj, "composePrivateMessage"), _obj)), _obj))));
});