define("discourse/plugins/discourse-events/discourse/models/connection", ["exports", "@ember/object", "discourse/lib/ajax", "discourse/lib/ajax-error", "discourse/models/site", "discourse-common/utils/decorators"], function (_exports, _object, _ajax, _ajaxError, _site, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  const Connection = _object.default.extend((_dec = (0, _decorators.default)("category_id"), (_obj = {
    category(categoryId) {
      const categories = _site.default.current().categoriesList;
      return categories.find(c => c.id === categoryId);
    }
  }, (_applyDecoratedDescriptor(_obj, "category", [_dec], Object.getOwnPropertyDescriptor(_obj, "category"), _obj)), _obj)));
  Connection.reopenClass({
    all() {
      return (0, _ajax.ajax)("/admin/events/connection").catch(_ajaxError.popupAjaxError);
    },
    update(connection) {
      return (0, _ajax.ajax)(`/admin/events/connection/${connection.id}`, {
        type: "PUT",
        contentType: "application/json",
        data: JSON.stringify({
          connection
        })
      }).catch(_ajaxError.popupAjaxError);
    },
    sync(connection) {
      return (0, _ajax.ajax)(`/admin/events/connection/${connection.id}`, {
        type: "POST"
      }).catch(_ajaxError.popupAjaxError);
    },
    destroy(connection) {
      return (0, _ajax.ajax)(`/admin/events/connection/${connection.id}`, {
        type: "DELETE"
      }).catch(_ajaxError.popupAjaxError);
    }
  });
  var _default = _exports.default = Connection;
});