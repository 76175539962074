define("discourse/plugins/discourse-events/discourse/components/events-calendar-card", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "discourse/lib/text", "discourse/lib/url", "discourse-common/utils/decorators"], function (_exports, _component, _object, _runloop, _text, _url, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.on)("init"), (_obj = {
    classNames: "events-calendar-card",
    attributeBindings: ["topic.id:data-topic-id"],
    setup() {
      const excerpt = this.get("topic.excerpt");
      const title = this.get("topic.title");
      (0, _text.cook)(excerpt).then(cooked => this.set("cookedExcerpt", cooked));
      (0, _text.cook)(title).then(cooked => this.set("cookedTitle", cooked));
    },
    didInsertElement() {
      this._super(...arguments);
      this.set("clickHandler", (0, _runloop.bind)(this, this.documentClick));
      (0, _runloop.next)(() => $(document).on("mousedown", this.get("clickHandler")));
      (0, _runloop.scheduleOnce)("afterRender", () => {
        const offsetLeft = this.element.closest(".day").offsetLeft;
        const offsetTop = this.element.closest(".day").offsetTop;
        const windowWidth = $(window).width();
        const windowHeight = $(window).height();
        let styles;
        if (offsetLeft > windowWidth / 2) {
          styles = {
            left: "-390px",
            right: "initial"
          };
        } else {
          styles = {
            right: "-390px",
            left: "initial"
          };
        }
        if (offsetTop > windowHeight / 2) {
          styles = Object.assign(styles, {
            bottom: "-15px",
            top: "initial"
          });
        } else {
          styles = Object.assign(styles, {
            top: "-15px",
            bottom: "initial"
          });
        }
        Object.keys(styles).forEach(key => {
          this.element.style[key] = styles[key];
        });
      });
    },
    willDestroyElement() {
      this._super(...arguments);
      $(document).off("mousedown", this.get("clickHandler"));
    },
    documentClick(event) {
      if (!event.target.closest(`div.events-calendar-card[data-topic-id='${this.topic.id}']`)) {
        this.clickOutside();
      }
    },
    clickOutside() {
      this.close();
    },
    close() {
      event?.preventDefault();
      this.selectEvent();
    },
    goToTopic() {
      event?.preventDefault();
      const url = this.get("topic.url");
      _url.default.routeTo(url);
    }
  }, (_applyDecoratedDescriptor(_obj, "setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "setup"), _obj), _applyDecoratedDescriptor(_obj, "close", [_object.action], Object.getOwnPropertyDescriptor(_obj, "close"), _obj), _applyDecoratedDescriptor(_obj, "goToTopic", [_object.action], Object.getOwnPropertyDescriptor(_obj, "goToTopic"), _obj)), _obj)));
});