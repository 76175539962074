define("discourse/plugins/discourse-events/discourse/components/events-calendar-body", ["exports", "@ember/component", "discourse-common/utils/decorators", "I18n", "discourse/plugins/discourse-events/discourse/lib/date-utilities"], function (_exports, _component, _decorators, _I18n, _dateUtilities) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.on)("init"), _dec2 = (0, _decorators.default)("responsive"), _dec3 = (0, _decorators.observes)("currentMonth"), (_obj = {
    classNames: "events-calendar-body",
    expandedDate: 0.0,
    setup() {
      this._super();
      moment.locale(_I18n.default.locale);
    },
    weekdays(responsive) {
      let data = moment.localeData();
      let weekdays = $.extend([], responsive ? data.weekdaysMin() : data.weekdays());
      let firstDay = (0, _dateUtilities.firstDayOfWeek)();
      let beforeFirst = weekdays.splice(0, firstDay);
      weekdays.push(...beforeFirst);
      return weekdays;
    },
    resetExpandedDate() {
      this.set("expandedDate", null);
    },
    actions: {
      setExpandedDate(date) {
        event?.preventDefault();
        const month = this.get("currentMonth");
        this.set("expandedDate", month + "." + date);
      }
    }
  }, (_applyDecoratedDescriptor(_obj, "setup", [_dec], Object.getOwnPropertyDescriptor(_obj, "setup"), _obj), _applyDecoratedDescriptor(_obj, "weekdays", [_dec2], Object.getOwnPropertyDescriptor(_obj, "weekdays"), _obj), _applyDecoratedDescriptor(_obj, "resetExpandedDate", [_dec3], Object.getOwnPropertyDescriptor(_obj, "resetExpandedDate"), _obj)), _obj)));
});